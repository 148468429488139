.app-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.bbh {
    pointer-events: all;
  }
  &.teaser {
    align-items: center;
    justify-content: center;
    padding: 4rem 0;

    .tease-img {
      max-width: 80rem;
      display: block;
      width: 100%;
      height: 100%;
      background-image: url("../../images/teaser-small.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    @include bp(s) {
      padding: 4rem;

      .tease-img {
        background-image: url("../../images/teaser.png");
      }
    }
  }
}

.placeholder {
  position: fixed;
  height: 100%;
  width: 100%;
  background: url("/images/placeholder.png") no-repeat center center;
  background-size: cover;
}
.app-wrapper {
  width: 100%;
  height: 100%;
  padding: 6rem 0 4rem 0;

  @include bp(s) {
    padding: 7rem 0 4rem 0;
  }
  #app-game {
    width: 100%;
    height: 100%;

    canvas.full-screen {
      width: 100%;
      height: 100%;
    }
  }
}
