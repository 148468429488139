footer {
  @include font-base;
  position: relative;
  height: $footer-height;
  border-top: $border-width solid #000;
  background-color: #fff;
  pointer-events: all;
}

.footer__banner {
  position: absolute;
  display: block;
  height: 6.7rem;
  width: 18rem;
  left: 50%;
  bottom: 0.5rem;
  transform: translateX(-50%);
  background: url(../../images/icons/banner.svg) no-repeat center center;
  background-size: contain;

  @include bp(s) {
    width: 28rem;
  }
}

@mixin round-buttons {
  @include reset-button;
  position: relative;
  height: 6rem;
  width: 6rem;
  border: .2rem solid #000;
  border: .2rem solid #000;
  border-radius: 3rem;
  background: white;
  transition: all 100ms;
  cursor: pointer;

  &:hover {
    transform: scale(1.15);
  }

  &:focus {
    outline: 0;
  }

  .icon {
    position: absolute;
    display: block;
    height: 2.475rem;
    width: 3.24rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    pointer-events: none;
  }
}

.footer__audio-btn {
  @include round-buttons;
  position: absolute;
  right: .8rem;
  bottom: 1rem;

  @include bp(s) {
    right: 2rem;
  }

  .icon {
    height: 2.475rem;
    width: 3.24rem;
    margin-left: 0.15rem;
    margin-top: 0.15rem;
    background-position: center left;
    background-image: url('/images/icons/audio-off.svg');
  }

  &.footer__audio-btn--is-enabled {
    .icon {
      height: 2.22rem;
      width: 2.39rem;
      margin-left: 0;
      margin-top: 0;
      background-image: url('/images/icons/audio.svg');
    }
  }
}

.footer__zoom-container {
  position: absolute;
  display: none;
  left: 2rem;
  bottom: 1rem;

  @include bp(s) {
    display: block;
  }
}

.footer__zoom-btn {
  @include round-buttons;

  .icon {
    margin-left: 0.1rem;
    margin-top: 0.1rem;
  }

  &.footer__zoom-btn--in .icon {
    background-image: url('/images/icons/zoom-in.svg');
  }
  
  &.footer__zoom-btn--out .icon {
    background-image: url('/images/icons/zoom-out.svg');
  }

  + button {
    margin-left: 1rem;
  }
}