.bbh-warning {
  height: 100%;
  display: flex;
  justify-content: center;

  header {
    border-color: transparent;
  }
  .warning-frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 2rem 8rem;
    max-width: 70rem;

    h3 {
      @include font-base();
      font-size: 2.2rem;
      margin: 2rem 0 1rem;
    }
    p {
      @include font-copy();
      font-size: 1.6rem;
      margin: 0 0 1rem;
    }
    .hunt-title {
      height: 9rem;
    }
    .warning-icon {
      width: 8rem;
      height: auto;
      display: block;
      margin: 0;
    }
  }
}
