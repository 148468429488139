.scan-screen {
  color: #000;

  .ar-ref {
    visibility: hidden;
  }
  .videoCanvas,
  .bug-anim,
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
  }
  .videoCanvas,
  .overlay {
    transition: opacity 0.5s ease;
  }
  .bug-anim {
    transition: all 1s ease;
    transform: translate(-50%, -50%) scale(0.2);
    transform-origin: center;
    width: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    text-align: center;
    opacity: 0;
    z-index: 1013;

    .lf-player-container,
    .ar-img {
      width: 50%;

      // svg {
      //   width: 100% !important;
      //   height: auto !important;
      // }
    }
    .collection-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      transition: all 0.8s ease;
      transition-delay: 1s;
      transform: translateY(0%);
      opacity: 0;

      p {
        margin: 0.5rem 0;
        font-size: 3.6rem;
      }
      h3 {
        margin: 0.5rem 0;
        font-size: 2.6rem;
      }
      @include mqh($short-mobile) {
        p {
          font-size: 3rem;
        }
        h3 {
          font-size: 2rem;
        }
      }
    }
  }

  &.enlarge {
    .videoCanvas,
    .overlay {
      opacity: 0;
    }
    .bug-anim {
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
      top: 40%;
      // left: 0;
      opacity: 1;

      .collection-info {
        transform: translateY(120%);
        opacity: 1;

        @include mqh($short-mobile) {
          transform: translateY(35%);
        }
      }
    }
  }

  // &.enlarge.with-detail {
  //   .bug-anim {
  //     transform: translate(-50%, -50%) scale(0.5) !important;
  //     top: 0;
  //     transition-duration: 1s;
  //     opacity: 0;
  //     pointer-events: none;
  //   }
  // }

  .instructions {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 103;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 100%;

    h3 {
      @include font-copy();
      font-weight: 500;
      font-size: 1.4rem;
      margin-top: 2rem;
    }
    .close-button {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
  }
  .debug-button {
    position: absolute;
    z-index: 1001;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    // display: none;
  }
}
