header {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: $header-height-mobile;
  padding: 0 2rem;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: $border-width solid #000;
  font-size: 1.6rem;
  pointer-events: all;
  z-index: $z-index-header;

  @include bp(s) {
    height: $header-height;
  }
}

// 1. Visually balance label with icon
.header__activities-btn {
  @include reset-button;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    .label {
      border-color: black;
    }
  }

  &:focus {
    outline: 0;
  }

  .icon {
    display: block;
    height: 2.5rem;
    width: 2.2rem;
    margin-left: .5rem;
    background: url('/images/icons/menu.svg') no-repeat center left;
    background-size: contain;
    transform: scale(1.1);
  }

  .label {
    @include font-base;
    display: none;
    padding: .4rem 0;
    border-bottom: $border-width solid transparent;
    font-size: 1.6rem;

    @include bp(s) {
      display: block;
      margin-top: .2rem;  // [1]
    }
  }

  
  
  &.header__activities-btn--is-open {
    .icon {
      background: url('/images/icons/close.svg') no-repeat center center;
    } 
  }
}

.header__logo {
  @include reset-button;
  display: block;
  position: absolute;
  height: 4.8rem;
  width: 10rem;
  left: 50%;
  top: 50%;
  margin-top: .2rem;
  transform: translate(-50%, -50%);
  background: url('/images/LB-header-mobile.png') no-repeat center center;
  background-size: contain;
  border-bottom: $border-width solid transparent;
  // cursor: pointer;

  @include bp(s) {
    height: 4rem;
    width: 21.5rem;
    background-image: url('/images/LB-header-desktop.png');
  }

  // &:hover,
  // &:focus {
  //   border-color:black;
  // }

  &:focus {
    outline: 0;
  }
}

.header__canyoufind-btn {
  @include reset-button;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    .label {
      border-color: black;
    }
  }

  &:focus {
    outline: 0;
  }

  .icon {
    display: block;
    height: 3.8rem;
    width: 2.6rem;
    margin-right: .6rem;
    //background: url('/images/icons/magnifying-glass.png') no-repeat center left;
    background-size: contain;
    transform: scale(1.1);
  }

  .label {
    @include font-base;
    display: none;
    padding: .4rem 0;
    border-bottom: $border-width solid transparent;
    font-size: 1.6rem;

    @include bp(s) {
      display: block;
      margin-top: .2rem;  // [1]
    }
  }

  &.header__canyoufind-btn--is-open {
    .icon {
      background: url('/images/icons/close.svg') no-repeat center center;
    } 
  }
}
.scoreboard {
  height: $header-height-mobile;
  display: flex;
  align-items: center;
  font-family: "DK-Lemon-Yellow-Sun";
  font-weight: 600;
  font-size: 2.4rem;

  @include bp(s) {
    height: $header-height;
  }
  .egg {
    display: block;
    width: 2.5rem;
    height: 3.2rem;
    background: url(../../images/icons/egg.svg) center no-repeat;
    transform-origin: center;
    will-change: transform;
    transition: background-image 0.3s ease;
    overflow: visible;
    margin-right: 0.5rem;
    transform: scale(1.01) rotate(0deg);
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 2.5rem;
      height: 3.2rem;
      background: url(../../images/icons/egg-color.svg) center no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
  &.grow {
    .egg {
      animation: eggGrow 0.7s ease-in-out 0s 1 forwards;

      &:after {
        opacity: 1;
      }
    }
  }
  .note {
    margin-left: 1rem;
    display: none;
    text-transform: uppercase;

    @include bp(s) {
      display: inline;
    }
  }
  .score {
    display: flex;
    align-items: center;
    overflow: visible;
  }
}


@keyframes eggGrow {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(25deg);
  }
  0% {
    transform: scale(1) rotate(0deg);
  }
}