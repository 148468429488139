.bbh-splash {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 8rem 7.5rem 3rem;
  text-align: center;
  overflow: hidden;

  @include mq($small-mobile) {
    padding: 8rem 5rem 3rem;
  }

  header {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    border-color: transparent;
    background-color: transparent;
  }

  .splash-frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: relative;
    z-index: 1;

    p {
      @include font-copy();
      font-size: 1.6rem;
      line-height: 1.375;
      margin: 0 0 1.5rem;

      &.welcome {
        font-size: 1.8rem;
      }
    }
    h3 {
      @include font-base;
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    @include mqh($short-mobile) {
      p {
        font-size: 1.3rem;
      }
      h3 {
        font-size: 2rem;
      }
      .logo-splash {
        width: 15rem;
        height: auto;
      }
    }
  }
  .decorations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.frame-0 {
      img {
        opacity: 0;
      }
    }

    img {
      height: 9rem;
      width: auto;
      display: block;
      position: absolute;
      transform-origin: center;
      // transform: translateX(-70%);
      transition: opacity 0.5s ease;
      pointer-events: none;
      opacity: 1;

      @include mqh($short-mobile) {
        height: 15rem;
      }

      &.egg01 {
        left: -8%;
        top: 20%;
        transform: rotate(-20deg);
      }
      &.egg02 {
        left: -8%;
        top: 70%;
        transform: rotate(18deg);
      }
      &.egg03 {
        right: -5%;
        top: 10%;
        transform: rotate(-15deg);
      }
      &.egg04 {
        right: -8%;
        top: 65%;
        transform: rotate(15deg);
      }

      &.moth,
      &.xmas,
      &.worm,
      &.owletmoth,
      &.huhu,
      &.glow {
        left: auto;
        right: 0;
        transform: translateX(70%);
      }
      &.xmas,
      &.huhu {
        top: 25%;
      }
      &.bee,
      &.beenz {
        top: -5%;
      }
      &.cicada,
      &.cicadanz {
        top: 40%;
      }
      &.moth,
      &.owletmoth {
        top: 0;
        transform: translate(60%, -50%);
      }
      &.worm,
      &.glow {
        bottom: 2%;
      }
      &.grub,
      &.weta {
        bottom: 0;
        transform: translate(-40%, 60%);
      }
    }
  }
}
