@font-face {
  font-family: "Winkle";
  src: url(../../fonts/Winkle-Regular.woff2) format("woff2"),
    url(../../fonts/Winkle-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DK-Lemon-Yellow-Sun";
  src: url(../../fonts/DKLemonYellowSun.woff2) format('woff2'),
  url(../../fonts/DKLemonYellowSun.woff) format('woff'),
  url(../../fonts/DK-Lemon-Yellow-Sun.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

