@mixin mq($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin mqh($height) {
  @media (max-height: $height) {
    @content;
  }
}
